<template>
  <div>
    <div class="card-toolbar mb-5">
      <!--      v-if="$can('general_sales.create')"-->
      <router-link to="/sales/general_sales_rent/create" class="btn btn-primary font-weight-bolder">
        <v-icon>mdi-plus</v-icon>
        {{ $t('sales_invoices.add_general_sales_rent') }}
      </router-link>
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row mb-5">

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="customer_id">{{ $t('sales_invoices.customer') }}</label>
              <multiselect
                  id="customer_id"
                  v-model="customer"
                  :placeholder="$t('sales_invoices.customer')"
                  label="fullname"
                  track-by="id"
                  :options="customers"
                  :multiple="false"
                  :taggable="false"
                  :show-labels="false"
                  :show-no-options="false"
                  :show-no-results="false"
                  @search-change="getCustomers($event)">
              </multiselect>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="invoice_code">{{ $t('sales_invoices.invoice_code') }}</label>
              <input v-model="filters.invoice_code" type="text" id="invoice_code" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_date">{{ $t('sales_invoices.from_date') }}</label>
              <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_date">{{ $t('sales_invoices.to_date') }}</label>
              <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="from_amount">{{ $t('sales_invoices.from_amount') }}</label>
              <input v-model="filters.from_amount" type="number" id="from_amount" class="form-control">
            </div>
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="to_amount">{{ $t('sales_invoices.to_amount') }}</label>
              <input v-model="filters.to_amount" type="number" id="to_amount" class="form-control">
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label>{{ $t('sales_invoices.currency') }}</label>
              <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                <option v-for="row in currencies" :value="row.id" :key="row.id">
                  {{ row.name }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">{{ $t('status') }}</label>
              <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
              </select>
            </div>

            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{ $t('search') }}</span>
                              </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{ $t('reset_search') }}</span>
                              </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::sales-->
    <div class="card card-custom">

      <div class="card-body">

        <!-- Filter -->
        <div>

        </div>
        <!-- End Filter -->

        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="status" slot-scope="props">
            <button v-if="$can('general_sales_rent.change_status')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ props.row.status_name }}
            </button>
            <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
              {{ props.row.status_name }}
            </button>
          </template>
          <template slot="actions" slot-scope="props">

            <div class="d-flex">
              <router-link v-if="$can('general_sales_rent.clone')" v-b-tooltip.hover :title="$t('clone')" class="mr-2 text-h-center" :to="`/sales/general_sales_rent/clone/${props.row.id}`">
                <i class="fa fa-copy text-info"></i>
              </router-link>
              <v-btn v-b-tooltip.hover v-if="$can('general_sales_rent.update')" :title="$t('edit')" icon color="pink" :to="`/sales/general_sales_rent/edit/${props.row.id}`">
                <v-icon small class="text-info">mdi-pencil</v-icon>
              </v-btn>


              <v-icon small class="text-danger" v-if="$can('general_sales_rent.delete')" v-b-tooltip.hover :title="$t('delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>

              <router-link v-if="$can('general_sales_rent.invoice_status')" v-b-tooltip.hover :title="$t('MENU.invoice_status')" class="mr-2 text-h-center" :to="`/sales/invoice-status/${props.row.id}`">
                <i class="fa fa-file-invoice text-info"></i>
              </router-link>


              <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('return_and_payment') " variant="outline-primary" class="m-2">
                <router-link class="dropdown-item" v-if="$can('general_sales_rent.payment_sales_invoices') && (props.row.status == 2 || props.row.status == 5)" :to="`/sales/payment_sales_invoices/create/${props.row.id}/invoice`">
                  {{ $t('MENU.payment_sales_invoices') }}
                </router-link>
                <router-link class="dropdown-item" v-if="$can('general_sales_rent.sales_refund') && props.row.status == 5" :to="`/sales/general_sales_refund/create/${props.row.id}`">
                  {{ $t('MENU.sales_refund') }}
                </router-link>
              </b-dropdown>

              <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('invoice_and_procedures') " variant="outline-primary" class="m-2">
                <template v-if="props.row.is_apply_taxes_invoice">
                  <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales_rent.printing_export_pdf')">
                    <label>{{ $t('tax_invoice_printing') }}</label>
                    <div class="d-flex justify-content-between">
                      <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-rent-tax-invoice/${props.row.id}/print`">
                        <i class="fas fa-print"></i>
                      </router-link>
                      <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-rent-tax-invoice/${props.row.id}/pdf`">
                        <i class="fas fa-file-pdf"></i>
                      </router-link>
                    </div>
                  </div>
                </template>
                <template v-else>
                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales_rent.printing_export_pdf')">
                  <label>{{ $t('MENU.invoice_printing') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-rent-tax-invoice/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-rent-tax-invoice/${props.row.id}/pdf`">
                      <i class="fas fa-file-pdf"></i>
                    </router-link>
                  </div>
                </div>
                </template>

                <!-- <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.pdf_print_tax_invoice_simplified')">
                  <label> {{ $t('tax_invoice_printing_simplified') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-tax-invoice/${props.row.id}/print/simplified`">
                      <i class="fas fa-print"></i>
                    </router-link>
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-tax-invoice/${props.row.id}/pdf/simplified`">
                      <i class="fas fa-file-pdf"></i>
                    </router-link>
                  </div>
                </div> -->

                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales_rent.printing_export_pdf')">
                  <label>{{ $t('MENU.invoice_printing_80_mm') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/general-sales-80mm-invoice/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/general-sales-80mm-invoice/${props.row.id}/pdf`">-->
<!--                      <i class="fas fa-file-pdf"></i>-->
<!--                    </router-link>-->
                  </div>
                </div>


              </b-dropdown>

              <b-dropdown :id="'dropdown-offset_'+props.row.id" :text="$t('shipping_and_delivery') " variant="outline-primary" class="m-2">


                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.pdf_print_packing_slip')">
                  <label> {{ $t('sales_printing.print_packing_slip') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/packing-general-sales/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/packing-general-sales/${props.row.id}/pdf`">-->
<!--                      <i class="fas fa-file-pdf"></i>-->
<!--                    </router-link>-->
                  </div>
                </div>


                <div class="dropdown-item d-flex justify-content-between" v-if="$can('general_sales.print_pdf_receive')">
                  <label> {{ $t('sales_printing.print_receive_list') }}</label>
                  <div class="d-flex justify-content-between">
                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/sales/receive-list-print-general-sales/${props.row.id}/print`">
                      <i class="fas fa-print"></i>
                    </router-link>
<!--                    <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/sales/receive-list-print-general-sales/${props.row.id}/pdf`">-->
<!--                      <i class="fas fa-file-pdf"></i>-->
<!--                    </router-link>-->
                  </div>
                </div>


                <!--                <router-link v-if="$can('general_sales.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('MENU.invoice_printing') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('export_invoice_pdf') }}-->
                <!--                </router-link>-->

                <!--                <router-link v-if="$can('general_sales.pdf_print_tax_invoice')" target="_blank" class="dropdown-item" :to="`/print/general-sales-tax-invoice/${props.row.id}/print`">-->
                <!--                  {{ $t('tax_invoice_printing') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.pdf_print_tax_invoice')" target="_blank" class="dropdown-item" :to="`/print/general-sales-tax-invoice/${props.row.id}/pdf`">-->
                <!--                  {{ $t('export_tax_invoice_pdf') }}-->
                <!--                </router-link>-->

                <!--                <router-link v-if="$can('general_sales.pdf_print_tax_invoice_simplified')" target="_blank" class="dropdown-item" :to="`/print/general-sales-tax-invoice/${props.row.id}/print/simplified`">-->
                <!--                  {{ $t('tax_invoice_printing_simplified') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.pdf_print_tax_invoice_simplified')" target="_blank" class="dropdown-item" :to="`/print/general-sales-tax-invoice/${props.row.id}/pdf/simplified`">-->
                <!--                  {{ $t('export_tax_invoice_pdf_simplified') }}-->
                <!--                </router-link>-->


                <!--                <router-link  v-if="$can('general_sales.pdf_print_packing_slip')" target="_blank" class="dropdown-item" :to="`/print/packing-general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('sales_printing.print_packing_slip') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.pdf_print_packing_slip')" target="_blank" class="dropdown-item" :to="`/print/packing-general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('sales_printing.pdf_packing_slip') }}-->
                <!--                </router-link>-->

                <!--                <router-link v-if="$can('general_sales.pdf_package')" target="_blank" class="dropdown-item" :to="`/print/sales/package-label-print-general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('sales_printing.print_package_label') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.pdf_package')" target="_blank" class="dropdown-item" :to="`/print/sales/package-label-print-general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('sales_printing.pdf_package_label') }}-->
                <!--                </router-link>-->

                <!--                <router-link v-if="$can('general_sales.print_pdf_receive')" target="_blank" class="dropdown-item" :to="`/print/sales/receive-list-print-general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('sales_printing.print_receive_list') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.print_pdf_receive')" target="_blank" class="dropdown-item" :to="`/print/sales/receive-list-print-general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('sales_printing.pdf_receive_list') }}-->
                <!--                </router-link>-->


                <!--                <router-link v-if="$can('general_sales.print_pdf_delivery')" target="_blank" class="dropdown-item" :to="`/print/sales/delivery-label-print-general-sales/${props.row.id}/print`">-->
                <!--                  {{ $t('sales_printing.print_delivery_label') }}-->
                <!--                </router-link>-->
                <!--                <router-link v-if="$can('general_sales.print_pdf_delivery')" target="_blank" class="dropdown-item" :to="`/print/sales/delivery-label-print-general-sales/${props.row.id}/pdf`">-->
                <!--                  {{ $t('sales_printing.pdf_delivery_label') }}-->
                <!--                </router-link>-->

              </b-dropdown>


            </div>

          </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
    <b-modal ref="status-modal" size="md" hide-footer :title="$t('change_status')">
      <change-status-form @hide-modal="hideModal()"
                          :status-list="status_list"
                          :url="routeChangeStatus"
                          :current-id="innerId"
                          :current-status="statusId"
                          @handling-data="getDataAfterChangeStatus">
      </change-status-form>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";


export default {
  name: "index-general-sales-order",
  components: {'change-status-form': ChangeStatusFrom,},
  data() {
    return {
      mainRoute: 'sales/general_sales_rents',
      routeChangeStatus: 'sales/general_sales_rent/change-status',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,

      filter: {
        sortBy: 'id',
      },
      filters: {
        customer_id: null,
        invoice_code: this.$route.query.invoice_number ? this.$route.query.invoice_number : '',
        from_date: null,
        to_date: null,
        currency_id: null,
        // status: null,
        from_amount: null,
        to_amount: null,
      },
      status_list: [],
      //'currency_name',
      columns: ['invoice_code', 'create_date', 'customer_name', 'invoice_total', 'total_paid_amount', 'is_paid_status_name', 'status', 'actions'],
      data: [],
      currencies: [],
      customers: [],

      innerId: null,
      statusId: null,
      customer: null,
      user: null,


    }
  },
  computed: {
    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          customer_name: that.$t('sales_invoices.customer'),
          create_date: that.$t('sales_invoices.create_date'),
          invoice_date: that.$t('sales_invoices.invoice_date'),
          invoice_code: that.$t('sales_invoices.invoice_code'),
          invoice_total: that.$t('sales_invoices.total'),
          currency_name: that.$t('sales_invoices.currency'),
          total_commission: that.$t('sales_invoices.total_commission'),
          is_paid_status_name: that.$t('sales_invoices.payment_status_label'),
          total_paid_amount: that.$t('paid_amount_label'),
          status: that.$t('status'),
          actions: that.$t('actions'),
        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'id'},

        alwaysShowPerPageSelect: false,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            perPage: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(`${that.mainRoute}`, {..._params});

        },
        responseAdapter(resp) {
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },
  watch: {

    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.general_rent_invoices")}]);
    if (this.filters.invoice_code) {
      this.showAdvancedSearch = true;
    }
    this.getCurrencies();
    // this.getCustomers();
    // this.getUsers();
    this.getStatusList();
  },
  methods: {
    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/sales_status").then((response) => {
        this.status_list = response.data.data;
      });
    },

    showModal() {
      this.$refs['status-modal'].show()
    },
    hideModal() {
      this.$refs['status-modal'].hide();
    },
    changeStatus(id, status) {
      this.innerId = id;
      this.statusId = status;
      this.showModal();
    },
    getDataAfterChangeStatus() {
      this.innerId = null;
      this.statusId = null;
      this.doFilter();
    },

    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.customer_id = null;
      this.filters.invoice_code = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.currency_id = null;
      this.filters.user_id = null;
      this.filters.status = null;
      this.filters.from_amount = null;
      this.filters.to_amount = null;
      this.user = null;
      this.customer = null;

      this.$refs.table.refresh();
    },
    getFetch() {
      this.$refs.table.refresh();
    },

    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getCustomers(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      } else {
        this.customers = [];
      }
    },


    actionDelete(item) {
      ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error);
      })
    },
  },
};
</script>
